import React, { useState } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import { MarkdownMedia } from "../components/markdownmedia/markdownmedia"
import { ImageGallery } from "../components/image-gallery/image-gallery"
import { Modal } from "../components/image-gallery/modal"

interface Props {
  data: any
}

const ProjectPageTemplate: React.FC<Props> = ({ data: { strapiProject } }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)

  // const markdownTypesAllowed = ['text', 'strong', 'delete', 'emphasis', 'link', 'image' ];

  const renderParagraph = props => {
    const { children } = props

    if (
      children &&
      children[0] &&
      children.length === 1 &&
      children[0].props &&
      children[0].props.src
    ) {
      // rendering media without p wrapper

      return children
    }

    return <p>{children}</p>
  }

  return (
    <Layout>
      <SEO title={strapiProject.title} />
      <div id="page-container">
        <div id="page-content">
          <h1>{strapiProject.title}</h1>
          <ReactMarkdown
            source={strapiProject.body}
            renderers={{
              image: props => (
                <MarkdownMedia
                  props={props}
                  videoSettings={{
                    showControls: false,
                    muted: true,
                    autoPlay: true,
                    loop: true,
                  }}
                />
              ),
              paragraph: renderParagraph,
            }}
          />
        </div>
        {strapiProject.imageGallery && (
          <>
            <ImageGallery
              images={strapiProject.imageGallery}
              setSelectedImageIndex={setSelectedImageIndex}
            />
            {selectedImageIndex !== null && (
              <Modal
                selectedImage={selectedImageIndex}
                setSelectedImage={setSelectedImageIndex}
                images={strapiProject.imageGallery}
              />
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default ProjectPageTemplate

export const PROJECT_QUERY = graphql`
  query ProjectQuery($id: String!) {
    strapiProject(id: { eq: $id }) {
      id
      title
      slug
      body
      imageGallery {
        id
        url
        alternativeText
      }
    }
  }
`
