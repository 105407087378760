import React from "react"
import { Media } from "../media/media"

interface Props {
  images: any
  setSelectedImageIndex: any
}

export const ImageGallery: React.FC<Props> = ({
  images,
  setSelectedImageIndex,
}) => {
  return (
    <div className="img-grid">
      {images?.map((image: any, index: number) => (
        <div
          key={image.id}
          className="img-wrap"
          onClick={() => {
            setSelectedImageIndex(index)
          }}
        >
          <Media
            mediaItem={image}
            mime="image"
            videoSettings={{
              autoPlay: true,
              loop: true,
              muted: true,
              showControls: false,
            }}
          />
        </div>
      ))}
    </div>
  )
}
