import React from "react"
import { Media } from "../media/media"

export const MarkdownMedia = ({ props, videoSettings }) => {
  if (props.src.includes(".mp4")) {
    return (
      <Media
        mime="video/"
        mediaItem={{ url: props.src, alternativeText: props.alt }}
        videoSettings={videoSettings}
      />
    )
  } else {
    return (
      <Media
        mime="image/"
        mediaItem={{ url: props.src, alternativeText: props.alt }}
      />
    )
  }
}
