import React from "react"
import { Media } from "../media/media"

// @ts-ignore
import ArrowRightIcon from "../../../assets/svg/arrow-right-solid.svg"
// @ts-ignore
import ArrowLeftIcon from "../../../assets/svg/arrow-left-solid.svg"
// @ts-ignore
import CloseIcon from "../../../assets/svg/times-circle-solid.svg"

interface Props {
  selectedImage: number
  setSelectedImage: any
  images: any
}

export const Modal: React.FC<Props> = ({
  selectedImage,
  setSelectedImage,
  images,
}) => {
  return (
    <div className="backdrop">
      <div
        className="direction-icon"
        onClick={e => {
          if (selectedImage - 1 >= 0) {
            setSelectedImage(selectedImage - 1)
          } else {
            setSelectedImage(images.length - 1)
          }
        }}
      >
        <ArrowLeftIcon />
      </div>
      <div
        className="close-button"
        onClick={e => {
          setSelectedImage(null)
        }}
      >
        <CloseIcon />
      </div>
      <Media
        mediaItem={{
          url: `${images[selectedImage]?.url}`,
          alternativeText: "Enlarged Pic",
        }}
        mime="image"
        videoSettings={{
          autoPlay: true,
          loop: true,
          muted: true,
          showControls: false,
        }}
      />
      <div
        className="direction-icon"
        onClick={e => {
          if (selectedImage + 1 < images.length) {
            setSelectedImage(selectedImage + 1)
          } else {
            setSelectedImage(0)
          }
        }}
      >
        <ArrowRightIcon />
      </div>
    </div>
  )
}
